import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css";
import "./simple-package.css";
import Nav from "../components/nav"
import AOS from 'aos';
import 'aos/dist/aos.css';

import imgTick from "../images/gfx/tick.png"
import imgHero from "../images/gfx/hero-simple-package.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-simple-package.jpg"
import Contact from "../components/contact";
import Footer from "../components/footer";

class IndexPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'issue',
      activeFaq: null
    };
  }

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  showTab(tab) {
    this.setState({
      activeTab: this.state.activeTab === tab ? null : tab
    });
  }

  showFaq(tab) {
    this.setState({
      activeFaq: this.state.activeFaq === tab ? null : tab
    });
  }

  render() {
    return (
      <Layout>
        <SEO title="Simple bus pass scanning | Simple package" description="Simplify your home to school services with ShuttleID. Payments, ticket distribution and scanning using a simple but cost-effective approach." />
        <Nav activeTab="packages" />
        <div>
            <div>

              <div className="hero-simple-package" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h2>Simple Package</h2>
                <h3>Bus pass scanning. For operators who want a simple solution for creating and scanning bus passes.</h3>
                <div className="hero__links">
                  <Link to="/book-demo/" className="btn">Book a demo</Link>
                </div>
              </div>

              <div className="mobile-hero-simple-package" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h2>Simple Package</h2>
                <h3>Bus pass scanning. For operators who want a simple solution for creating and scanning bus passes.</h3>
                <div className="hero__links">
                  <Link to="/book-demo/" className="btn">Book a demo</Link>
                </div>
              </div>

              <div className='package-page'>

                <div className="package-page__main">

                  {/* <h3>Benefits</h3>
                  <table className="benefits">
                    <tbody>
                      <tr>
                        <td className="benefits__icon"><img className="tick" src={imgTick} alt="" /></td>
                        <td>Everything you need for scanning bus passes in a simple system</td>
                      </tr>
                      <tr>
                        <td className="benefits__icon"><img className="tick" src={imgTick} alt="" /></td>
                        <td>Save time by bulk-loading passenger data from spreadsheets</td>
                      </tr>
                      <tr>
                        <td className="benefits__icon"><img className="tick" src={imgTick} alt="" /></td>
                        <td>Digital tickets reduce admin (enormously) and your carbon footprint (a bit)</td>
                      </tr>
                      <tr>
                        <td className="benefits__icon"><img className="tick" src={imgTick} alt="" /></td>
                        <td>Automatic ticket checks prevent fraud and driver admin</td>
                      </tr>
                      <tr>
                        <td className="benefits__icon"><img className="tick" src={imgTick} alt="" /></td>
                        <td>Enhanced safeguarding for schools through Scan Logs</td>
                      </tr>
                    </tbody>
                  </table> */}

                  <h3>How it works</h3>
                  <p>Find out how the Simple Package can help you issue, scan and manage passes for your school services. Need live vehicle tracking, real-time passenger data and boarding notifications? Check out our new <Link className="link" to="/school-bus-tracking-system-app/">LIVE add-on</Link>. The Simple Package is backed by a <Link className="link" style={{ fontWeight: 'bold' }} to="/money-back-guarantee/">100-day money back guarantee</Link>.</p>

                  <div className='package__how-it-works'>

                    <div className={`${this.state.activeTab === 'issue' ? 'active' : ''} package__how-it-works__item`}>
                      <div className="package__how-it-works__head" onClick={() => { this.showTab('issue')}}>
                        <span>1. Issue your tickets</span>
                        <span className="package__how-it-works__head-symbol">
                          {this.state.activeTab === 'issue' && (<>-</>)}
                          {this.state.activeTab !== 'issue' && (<>+</>)}
                        </span>
                      </div>
                      <div className="package__how-it-works__body">
                        <p className="package__how-it-works__highlight">Send hundreds of bus passes with one click</p>
                        <ul>
                          <li>Easy CSV import or single passenger data entry</li>
                          <li>One click to bulk send tickets to parents by email</li>
                          <li>Parent prints off instantly or forward ticket to passenger's mobile</li>
                          <li>24/7 access to tickets means no more lost or "forgotten" passes</li>
                          <li style={{ margin: 0 }}>Scanner technology ensures tickets cannot be shared</li>
                        </ul>
                      </div>
                      {/* <button className="package__how-it-works__next-btn btn" onClick={() => { this.showTab('scan')}}>
                        <span>Next: Scan tickets </span><img src={imgArrowDown} alt="" />
                      </button> */}
                    </div>

                    <div className={`${this.state.activeTab === 'scan' ? 'active' : ''} package__how-it-works__item`}>
                      <div className="package__how-it-works__head" onClick={() => { this.showTab('scan')}}>
                        <span>2. Scan your tickets</span>
                        <span className="package__how-it-works__head-symbol">
                          {this.state.activeTab === 'scan' && (<>-</>)}
                          {this.state.activeTab !== 'scan' && (<>+</>)}
                        </span>
                      </div>
                      <div className="package__how-it-works__body">
                        <p className="package__how-it-works__highlight">Check passes quickly and accurately with the Scanner</p>
                        <ul>
                          <li>Tablet device is mounted using a flexible stand but not hardwired</li>
                          <li>Passengers self-scan their mobile or printed ticket</li>
                          <li>Alerts driver to duplicate tickets, expired tickets, voided tickets and more</li>
                          <li>Driver only needs to intervene when a ticket is flagged</li>
                          <li style={{ margin: 0 }}>Scan logs keep a record of who boarded and when for your operations team</li>
                        </ul>
                      </div>
                      {/* <button className="package__how-it-works__next-btn btn" onClick={() => { this.showTab('manage')}}>
                        <span>Next: Manage </span><img src={imgArrowDown} alt="" />
                      </button> */}
                    </div>

                    <div className={`${this.state.activeTab === 'manage' ? 'active' : ''} package__how-it-works__item`}>
                      <div className="package__how-it-works__head" onClick={() => { this.showTab('manage')}}>
                        <span>3. Manage</span>
                        <span className="package__how-it-works__head-symbol">
                          {this.state.activeTab === 'manage' && (<>-</>)}
                          {this.state.activeTab !== 'manage' && (<>+</>)}
                        </span>
                      </div>
                      <div className="package__how-it-works__body">
                        <p className="package__how-it-works__highlight">Manage everything from the Operations Portal</p>
                        <ul>
                          <li>Scan summary alerts you to problems across your fleet at a glance</li>
                          <li>Detailed scan logs created for services and individual passengers</li>
                          <li>View detailed passenger data including parent contact info</li>
                          <li>Move passengers between services to maximise capacity</li>
                          <li>Create new services or release more tickets as demand dictates</li>
                          <li>Send SMS service alerts to ticket holders</li>
                          <li style={{ margin: 0 }}>Void tickets instantly without the hassle of trying to collect passes back</li>
                        </ul>
                      </div>
                      <Link to="/contact/" className="package__how-it-works__next-btn btn">
                        <span>Contact us to find out more</span>
                      </Link>
                    </div>

                  </div>

                  <div className="package__more-info">
                    <h4>Have further questions?</h4>
                    <ul>
                      <li>Call our friendly specialist team on 0333 344 9868</li>
                      <li>Complete our enquiry form for more details and pricing</li>
                      <li>You might find your answer in our FAQ section below</li>
                    </ul>
                  </div>

                </div>

                <div className="package-page__pricing">
                  <div className="package-page__pricing-info">
                    <h3 id="pricing">Pricing</h3>
                    <table>
                      <tbody>
                        <tr>
                          <th style={{ width: '10%' }}>Setup</th>
                          <td style={{ width: '90%', textAlign: 'left' }}>FREE</td>
                        </tr>
                        <tr>
                          <th>Hardware</th>
                          <td style={{ textAlign: 'left' }}>From £50</td>
                        </tr>
                        <tr>
                          <th>Platform</th>
                          <td style={{ textAlign: 'left' }}>Starting at £50/month. Banded by passenger volume</td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="highlight no-margin">Complete the enquiry form below for more details and accurate pricing based on your requirements:</p>
                  </div>

                  <form style={{ textAlign: 'left' }} action="/simple-package-pricing-requested/" id="pricing-simple" name="pricing-simple-package" method="POST" className="package-page__pricing-form" data-netlify="true" data-netlify-honeypot="bot-field">

                    <p style={{ display: 'none' }}>
                      <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                    </p>
                    <div className="package-page__pricing-form__group">
                      <label>Your name *</label>
                      <input placeholder="Your name *" name="name" required className="package-page__pricing-form__input" />
                    </div>
                    <div className="package-page__pricing-form__group">
                      <label>Company *</label>
                      <input placeholder="Company *" name="company" required className="package-page__pricing-form__input" />
                    </div>
                    <div className="package-page__pricing-form__group">
                      <label>Email *</label>
                      <input placeholder="Email *" name="email" type="email" required className="package-page__pricing-form__input" />
                    </div>
                    <div className="package-page__pricing-form__group">
                      <label>Phone *</label>
                      <input placeholder="Phone *" name="phone" required className="package-page__pricing-form__input" />
                    </div>
                    <div className="package-page__pricing-form__group">
                      <label>Approx number of passengers *</label>
                      <input placeholder="Approx number of passengers *" name="num_passengers" required className="package-page__pricing-form__input" />
                    </div>
                    <input type="hidden" name="form-name" value="pricing-simple-package" />
                    <p>
                      <button className="btn" type="submit">Request details and pricing</button>
                    </p>
                  </form>
                </div>

                <div className="package-page__faq">
                  <h3>FAQ</h3>
                  <div className='package__faq'>

                    <div className='package__faq-column'>

                      <div className={`${this.state.activeFaq === 'faq1' ? 'active' : ''} package__faq__item`}>
                        <div className="package__faq__head" onClick={() => { this.showFaq('faq1')}}>
                          <span>Q: How long does it take to get set up?</span>
                          <span className="package__faq__head-symbol">
                            {this.state.activeFaq === 'faq1' && (<>-</>)}
                            {this.state.activeFaq !== 'faq1' && (<>+</>)}
                          </span>
                        </div>
                        <div className="package__faq__body">
                          <p>Setup time can take as little as one day for issuing bus passes to passengers.</p>
                          <p>Ordering and setting up your devices for ticket scanning is typically turned around within one week.</p>
                        </div>
                      </div>

                      <div className={`${this.state.activeFaq === 'faq2' ? 'active' : ''} package__faq__item`}>
                        <div className="package__faq__head" onClick={() => { this.showFaq('faq2')}}>
                          <span>Q: What about passengers that don't have a smartphone?</span>
                          <span className="package__faq__head-symbol">
                            {this.state.activeFaq === 'faq2' && (<>-</>)}
                            {this.state.activeFaq !== 'faq2' && (<>+</>)}
                          </span>
                        </div>
                        <div className="package__faq__body">
                          <p>We understand that not every child has a smartphone. ShuttleID is designed to work as both a printed ticket and mobile ticket.</p>
                          <p>This means that passengers without a smartphone can print out their own paper pass instead.</p>
                          <p>Ticket scanning ensures that passes can't be shared. The barcode on the paper ticket can withstand up to 30% damage.</p>
                          <p>If a paper ticket is severely damaged or lost, passengers, parents or schools can just print out another one, without having to bother you or incurring a frustrating pass-replacement fee.</p>
                        </div>
                      </div>

                    </div>

                    <div className='package__faq-column'>

                      <div className={`${this.state.activeFaq === 'faq3' ? 'active' : ''} package__faq__item`}>
                        <div className="package__faq__head" onClick={() => { this.showFaq('faq3')}}>
                          <span>Q: Can this only be used with school services?</span>
                          <span className="package__faq__head-symbol">
                            {this.state.activeFaq === 'faq3' && (<>-</>)}
                            {this.state.activeFaq !== 'faq3' && (<>+</>)}
                          </span>
                        </div>
                        <div className="package__faq__body">
                          <p>Whilst the system is primarily designed with home-to-school services in mind, we also work with a growing number of operators who are using the system for other use cases.</p>
                          <p>Get in touch to discuss your requirements and we can work with you to explore how viable our system is for your intended use.</p>
                        </div>
                      </div>

                      <div className={`${this.state.activeFaq === 'faq4' ? 'active' : ''} package__faq__item`}>
                        <div className="package__faq__head" onClick={() => { this.showFaq('faq4')}}>
                          <span>Q: How can I get the contact details of the passengers?</span>
                          <span className="package__faq__head-symbol">
                            {this.state.activeFaq === 'faq4' && (<>-</>)}
                            {this.state.activeFaq !== 'faq4' && (<>+</>)}
                          </span>
                        </div>
                        <div className="package__faq__body">
                          <p>If you're providing a service on behalf of a client (e.g. a school or local authority) they should be able to provide you with the minimum detail of passenger name and contact email address (parent).</p>
                          <p>If this data cannot be supplied, you can collect it directly from passengers via our waiting list feature.</p>
                          <p>The waiting list sign-up form can be sent out via email and social media or linked to from the school or your own website. All new leads are accessible to you in the Operations Portal, where you can import them as passengers and issue their tickets.</p>
                          <p>It's a proven model that works very well when data is not readily available, and our team will support you with this process should you need it. We can show you exactly how this all works in our <Link to='/book-demo/'>demo</Link>.</p>
                        </div>
                      </div>



                    </div>

                  </div>

                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default IndexPage
